.projects-section {
  padding: 40px;
  background-color: #0F1E12;
  color: #fff;
  text-align: center;
}

.projects-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.tabs {
  margin-bottom: 20px;
}

.tabs button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #1e3a4c;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tabs button.active,
.tabs button:hover {
  background-color: #fff;
  color: #1e3a4c;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 6px;
  background-color: #fff;
  transform: translateX(-50%);
}

.timeline-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 20px 0;
}

.timeline-item.left .project-card {
  order: 1;
  margin-right: 80px;
}

.timeline-item.left .year {
  order: 2;
  margin-left: 80px;
}

.timeline-item.right .project-card {
  order: 2;
  margin-left: 80px;
}

.timeline-item.right .year {
  order: 1;
  margin-right: 80px;
}

.extra-info,
.project-card {
  flex: 1;
}

.year {
  font-size: 2.5em;
  font-weight: bold;
  color: #d1e8e2;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 320px;
  font-family: 'Lobster', cursive;
}

/* Projects List for 'Obras en Ejecución' */
.projects-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows items to wrap to the next line if necessary */
  gap: 20px; /* Adjust the gap between cards as needed */
  max-width: 1000px;
  margin: auto;
}

.projects-list .project-card-container {
  flex: 1 1 calc(33.333% - 40px); /* Adjusts cards to take up 1/3 of the container width */
  max-width: calc(33.333% - 40px); /* Adjusts cards to take up 1/3 of the container width */
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .projects-section {
    padding: 20px;
  }

  .projects-section h2 {
    font-size: 1.8em;
    margin-bottom: 30px; /* Increased margin-bottom to add space between title and buttons */
  }

  .tabs {
    margin-bottom: 20px;
  }

  .tabs button {
    margin: 0 5px;
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .timeline {
    padding: 20px 10px;
  }

  .timeline:before {
    left: 50%;
  }

  .timeline-item {
    flex-direction: column;
    align-items: center;
  }

  .timeline-item.left .project-card,
  .timeline-item.right .project-card {
    margin: 20px 0;
  }

  .timeline-item.left .year,
  .timeline-item.right .year {
    display: none;
  }

  .project-card {
    max-width: 90%;
    margin: 10px 0;
  }

  .content {
    padding: 15px;
  }

  .content h3 {
    font-size: 1.2em;
  }

  .additional-content .subtitle,
  .additional-content .details,
  .additional-content p,
  .year {
    font-size: 0.9em;
  }

  .projects-list .project-card-container {
    flex: 1 1 100%; /* Each card takes up full width on smaller screens */
    max-width: 100%; /* Each card takes up full width on smaller screens */
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .projects-section {
    padding: 20px;
    margin-top: 75px;

  }

  .projects-section h2 {
    font-size: 1.8em;
    margin-bottom: 30px; /* Increased margin-bottom to add space between title and buttons */
  }

  .tabs {
    margin-bottom: 20px;
  }

  .tabs button {
    margin: 0 5px;
    padding: 8px 16px;
    font-size: 0.9em;
  }

  .timeline {
    padding: 20px 10px;
  }

  .timeline:before {
    left: 50%;
  }

  .timeline-item {
    flex-direction: column;
    align-items: center;
  }

  .timeline-item.left .project-card,
  .timeline-item.right .project-card {
    margin: 20px 0;
  }

  .timeline-item.left .year,
  .timeline-item.right .year {
    display: none;
  }

  .project-card {
    max-width: 90%;
    margin: 10px auto; /* Center the card and add margin to top and bottom */
  }

  .content {
    padding: 15px;
  }

  .content h3 {
    font-size: 1.2em;
  }

  .additional-content .subtitle,
  .additional-content .details,
  .additional-content p,
  .year {
    font-size: 0.9em;
  }

  .projects-list {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the cards */
  }

  .projects-list .project-card-container {
    flex: 1 1 100%; /* Each card takes up full width on smaller screens */
    max-width: 90%; /* Center the card */
    margin: 10px auto; /* Center the card and add margin to top and bottom */
  }
}

@media (max-width: 480px) {
  .projects-section {
    padding: 15px;
    margin-top: 75px;
  }

  .projects-section h2 {
    font-size: 1.5em;
    margin-bottom: 30px; /* Further increased margin-bottom for smaller screens */
  }

  .tabs {
    margin-bottom: 15px;
  }

  .tabs button {
    margin: 0 3px;
    padding: 6px 12px;
    font-size: 0.8em;
  }

  .image-container {
    height: 150px;
  }

  .content {
    padding: 10px;
  }

  .content h3 {
    font-size: 1em;
  }

  .additional-content .subtitle,
  .additional-content .details,
  .additional-content p,
  .year {
    font-size: 0.8em;
  }

  .projects-list {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the cards */
  }

  .projects-list .project-card-container {
    flex: 1 1 100%; /* Each card takes up full width on smaller screens */
    max-width: 90%; /* Center the card */
    margin: 10px auto; /* Center the card and add margin to top and bottom */
  }
}
