a:visited {
  color: unset; /* Ensure visited links remain the same color */
}

#header {
  background-color: #fffef4;
  width: 100%;
}

#header-menu {
  top: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
}

#header-logo > img {
  width: 100px;
  height: auto;
}

.menu-icon {
  display: none; /* Hide menu icon by default on larger screens */
  cursor: pointer;
}

.header-nav {
  color: #155049;
  margin: 0;
  display: flex;
  height: auto;
}

@media (min-width: 860px) {
  .header-nav-option {
    display: inline-block;
    position: relative;
    padding: 32px 15px;
    font-weight: 620;
    cursor: pointer;
  }

  .header-nav-option > a {
    text-decoration: none;
    color: #155049;
  }

  .header-nav-option > a:hover {
    color: #609262; /* Hover color for links */
  }

  .header-nav-option::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: transparent;
    transition: background-color 0.2s ease;
  }

  .header-nav > .header-nav-option:hover::after {
    background-color: #155049;
  }

  .sublinks {
    list-style-type: none;
    font-weight: lighter;
    text-align: left;
    margin: 0;
    padding: 0;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    z-index: auto;
  }

  .header-nav-option.sublink-item {
    padding: 0;
  }

  .header-nav-option.has-sublinks > .sublinks {
    display: none;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .header-nav-option.has-sublinks:hover > .sublinks {
    display: block;
    opacity: 1;
  }

  .sublinks > li > a {
    text-decoration: none;
    color: #333;
    display: block;
    padding: 20px 35px;
    transition: background-color 0.3s ease;
    width: 161px;
  }

  .sublinks > li > a:hover {
    color: #fff;
    background-color: #155049;
  }

  .sublinks.show-menu {
    display: block;
  }
}

@media (max-width: 860px) {

  #header-menu {
    width: 100%;
    position: fixed;
    z-index: 10000;
    background-color: #fff;
    padding: 2px 20px;
  }

  .menu-icon {
    display: flex;
    margin-left: auto;
  }

  .header-nav {
    text-align: left;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  }

  .header-nav.show-menu {
    display: flex;
    padding: 0;
    opacity: 1;
  }

  .header-nav-option {
    font-weight: 500;
    color: black;
  }

  .header-nav-link {
    text-decoration: none;
    display: flex;
    padding: 10px 30px;
  }

  .has-sublinks > ul.show-menu {
    padding-left: 0;
    display: block;
    list-style-type: none;
    opacity: 1;
  }

  .sublinks {
    display: none;
  }

  .sublinks > li:first-child {
    border-top: 1px solid #e7e6e6;
  }

  .sublinks > li:last-child {
    border-bottom: none;
  }

  .header-nav-option,
  .sublinks > li {
    list-style-type: none;
    width: 100%;
    border-bottom: 1px solid #e7e6e6;
    align-items: center;
  }

  .has-sublinks > ul.show-menu {
    display: block;
    list-style-type: none;
    opacity: 1;
  }

  .sublink-item > .header-nav-link {
    padding-left: 30px;
  }

  .header-nav-option.sublink-item {
    font-weight: 350;
    color: rgb(149, 149, 149);
    padding-left: 40px;
  }
}
