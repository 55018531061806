.project-card {
  background-color: #2a4d64;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  max-width: 300px;
  margin: 10px;
  position: relative;
}

.project-card:hover {
  transform: scale(1.05); /* Smoothly scales the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.image-container {
  width: 100%;
  height: 200px; /* Fixed height for the images */
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.content {
  padding: 20px;
  background-color: #2a4d64;
  color: #fff;
  transition: max-height 0.5s ease-in-out;
  max-height: 70px; 
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-card:hover .content {
  max-height: 400px; /* expanded height */
}

.content h3 {
  font-size: 1.5em;
  margin: 0;
  padding: 0;
  text-align: center;
}

.additional-content {
  max-height: 0; /* Hide additional content by default */
  transition: max-height 0.5s ease-in-out; /* Smooth transition for height */
  overflow: hidden;
}

.project-card:hover .additional-content {
  max-height: 300px; /* Show additional content on hover */
}

.additional-content .subtitle {
  font-size: 1.2em;
  font-weight: normal;
  margin-bottom: 10px;
  color: #d1e8e2;
}

.additional-content .details {
  font-size: 1em;
  margin-bottom: 10px;
  font-weight: bold;
}

.additional-content p {
  white-space: pre-wrap;
  line-height: 1.5;
  margin-top: 20px;
}

.extra-info {
  margin-top: 10px;
  opacity: 0;
  max-height: 0;
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  text-align: center;
}

.project-card:hover .extra-info {
  opacity: 1;
  max-height: 200px; /* Adjust as needed */
}

.extra-info .year {
  font-size: 2.5em;
  font-weight: bold;
  color: #d1e8e2;
  margin: 10px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .project-card {
    max-width: 100%;
    margin: 10px 0;
  }

  .content {
    padding: 15px;
  }

  .content h3 {
    font-size: 1.2em;
  }

  .additional-content .subtitle,
  .additional-content .details,
  .additional-content p,
  .extra-info .year {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .image-container {
    height: 150px; /* Adjusted height for smaller screens */
  }

  .content {
    padding: 10px;
  }

  .content h3 {
    font-size: 1em;
  }

  .additional-content .subtitle,
  .additional-content .details,
  .additional-content p,
  .extra-info .year {
    font-size: 0.8em;
  }
}